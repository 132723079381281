import { motion, SVGMotionProps } from 'framer-motion';
import React from 'react';

const BgDonut3 = (props: SVGMotionProps<SVGSVGElement>) => (
  <motion.svg
    xmlns="http://www.w3.org/2000/svg"
    width="259"
    height="251"
    fill="none"
    viewBox="0 0 259 251"
    {...props}
  >
    <g clipPath="url(#clip0_212_11896)">
      <path
        fill="#F4EBDF"
        d="M117.711 139.839a23.93 23.93 0 01-4.27-3.51c-9.45-10 1.18-20.46 7.63-28.27.86-1.05 3.34-3.58 4-4.37 1.07-.68 2.28-1.38 3.11-1.89.81-.33 1.61-.61 2.42-.85.93.4.7.24 0 0l.34-.1c1.24.21 2.71.44 3.41.52 1.06.43 3.15 1.2 3.48 1.36.14.14.31.29.52.47.69.61 1.4 1.22 2.09 1.85.24.42 1.66 2.2 1.9 2.56.95 1.44 1.91 2.87 2.78 4.36.1.24.27.68.59 1.43.88 2.06 1.64 4.14 2.38 6.25.34 1 1.2 4.5 1.29 4.61.51 3.167.8 6.364.87 9.57-.05.18-.1.39-.16.67-.27 1.23-.55 2.61-.72 3.88-.65 4.88-5.41 7.28-9.78 7.63-.79.06-1.41.09-1.94.1.58.07 1.25.17 2.1.34-.76-.16-1.53-.25-2.31-.34-1.62 0-1.93-.13-1.1-.11-1.39-.13-2.78-.24-4.13-.49a40.988 40.988 0 01-14.5-5.67zm-66.8 80.79c20.8 16.06 37 26.67 64.36 29.69 30.1 3.33 54.62-6 79.82-20.88 28.59-16.9 52.49-39.77 59.54-69.6a141.886 141.886 0 00-1.66-70.26c-8.83-31.64-29.37-50.77-59.12-67.23-28.08-15.55-61.74-27.89-92.94-19.67-27.06 7.09-51.71 22.67-67.71 45.89-1.52 2.22-2.94 4.5-4.3 6.82a131 131 0 00-28.88 78.45c-.72 32.78 22.18 64.58 50.89 86.77"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_212_11896">
        <path
          fill="#fff"
          d="M0 0H258.26V250.96H0z"
          transform="translate(.001 .008)"
        ></path>
      </clipPath>
    </defs>
  </motion.svg>
);

export default BgDonut3;
