import Flicking from '@egjs/react-flicking';
import { AnimatePresence } from 'framer-motion';
import { Link } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import React, { useEffect, useRef, useState } from 'react';
import Button from '../atoms/Button';
import Image from '../atoms/Image';
import ArrowButton from '../molecules/ArrowButton';
import MenuItemPopup from '../molecules/MenuItemPopup';

export interface MenuItem {
  id: string;
  title: string;
  saltyMenuItem: {
    description: string;
    glutenFree: boolean;
    vegan: boolean;
    orderNowUrl: string;
    photo: {
      localFile: any;
    };
    gallery: Array<{
      altText: string;
      localFile: any;
    }>;
  };
}

interface ExploreTheMenuSectionProps {
  heading: string;
  items: any[];
  buttonLabel: string;
  buttonUrl: string;
}

const ExploreTheMenuSection = ({
  heading,
  items,
  buttonLabel,
  buttonUrl,
}: ExploreTheMenuSectionProps) => {
  const ref = useRef<Flicking>(null);
  const [intervalId, setIntervalId] = useState<NodeJS.Timer>(null);
  const [openItem, setOpenItem] = useState<any>(null);

  const resetScrollInterval = () => {
    if (intervalId) {
      clearInterval(intervalId);
    }

    if (!ref.current) {
      return;
    }

    const interval = setInterval(() => {
      ref.current?.next().catch(() => void 0);
    }, 2500);

    setIntervalId(interval);
  };

  const next = async () => {
    ref.current?.next().catch(() => void 0);
    resetScrollInterval();
  };

  const prev = async () => {
    ref.current?.prev().catch(() => void 0);
    resetScrollInterval();
  };

  useEffect(() => {
    resetScrollInterval();

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <section className="pt-32 pb-16 md:pt-48 md:pb-32 bg-linen">
        <div className="container">
          <h2 className="mb-12 text-center md:mb-16 u-h2">{heading}</h2>
        </div>
        <div className="relative flex justify-center mb-8 space-x-4">
          <ArrowButton
            variant="left"
            className="absolute z-30 -mt-14 left-6 md:hidden top-1/2"
            onClick={prev}
            ariaLabel="Previous"
          />
          <ArrowButton
            variant="right"
            className="absolute z-30 -mt-14 right-6 md:hidden top-1/2"
            onClick={next}
            ariaLabel="Next"
          />
          <Flicking
            renderOnlyVisible={true}
            autoInit
            circular
            align="center"
            interruptable={false}
            ref={ref}
          >
            {[...items, ...items].map((item, index) => (
              <div className="px-4 md:py-8 w-80" key={`${index}-${item.id}`}>
                <Link
                  to="/menu"
                  className="flex flex-col items-center justify-center pb-8 h-80 text-center transition md:hover:bg-white rounded-[10px] md:hover:shadow-card group"
                  aria-label="Donut"
                >
                  <Image
                    image={getImage(item.localFile)}
                    alt={item.altText}
                    className="object-contain w-auto h-full"
                  />
                </Link>
              </div>
            ))}
          </Flicking>
        </div>

        <div className="container">
          <div className="flex items-center justify-center md:justify-between">
            <ArrowButton
              variant="left"
              className="hidden md:flex"
              onClick={prev}
              ariaLabel="Previous"
            />
            {buttonUrl && <Button to={buttonUrl}>{buttonLabel}</Button>}
            <ArrowButton
              variant="right"
              className="hidden md:flex"
              onClick={next}
              ariaLabel="Next"
            />
          </div>
        </div>
      </section>
      <AnimatePresence exitBeforeEnter>
        {openItem && (
          <MenuItemPopup
            key={openItem.id}
            item={openItem}
            onClose={() => setOpenItem(null)}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default ExploreTheMenuSection;
