import { motion, useTransform, useViewportScroll } from 'framer-motion';
import React, { useState } from 'react';
import { useScrollTo } from '../../hooks/use-scroll-to';
import RoundButtonBg from '../../images/round-button-bg.svg';
import ArrowRightIcon from '../atoms/ArrowRightIcon';
import LogoOutline from '../atoms/LogoOutline';

interface HomepageHeroSectionProps {
  videoUrl: string;
  posterUrl: string;
}

const HomepageHeroSection = ({
  videoUrl,
  posterUrl,
}: HomepageHeroSectionProps) => {
  const { scrollY } = useViewportScroll();
  const { scrollTo } = useScrollTo();
  const y1 = useTransform(scrollY, [0, 675], [0, 300], { clamp: false });
  const y2 = useTransform(scrollY, [0, 675], [0, 100], { clamp: false });
  const y3 = useTransform(scrollY, [0, 675], [0, 100], { clamp: false });
  const opacity = useTransform(scrollY, [0, 675], [1, 0]);
  const [isDownArrowVisible, setDownArrowVisible] = useState(true);

  scrollY.onChange((value) => {
    if (isDownArrowVisible && value > 30) {
      setDownArrowVisible(false);
      return;
    }

    if (!isDownArrowVisible && value <= 30) {
      setDownArrowVisible(true);
    }
  });
  const handleScrollToContent = () => {
    scrollTo('find-your-store');
  };

  return (
    <section className="relative overflow-hidden">
      <motion.div
        className="absolute inset-0 z-0 bg-center bg-cover"
        style={{ backgroundImage: `url(${posterUrl})`, y: y3 }}
      >
        <video
          aria-hidden="true"
          crossOrigin="anonymous"
          playsInline
          preload="auto"
          muted
          loop
          autoPlay
          tabIndex={-1}
          width="100%"
          height="100%"
          src={videoUrl}
          poster={posterUrl}
          className="object-cover object-center w-full h-full opacity-100"
        ></video>
      </motion.div>
      <div className="absolute inset-0 z-0 bg-opacity-20 bg-navy-blue" />
      <div className="flex items-center justify-center h-[667px] md:h-[675px]">
        <motion.h1
          className="relative px-12 md:px-0"
          style={{ y: y1, opacity }}
        >
          <LogoOutline className="w-full h-auto md:w-auto md:h-50" />
          <div className="absolute left-0 right-0 mt-8 top-full">
            <motion.button
              type="button"
              className="flex items-center justify-center w-12 h-12 mx-auto bg-center bg-no-repeat bg-contain rounded-full focus:outline-none"
              style={{ backgroundImage: `url(${RoundButtonBg})` }}
              aria-label="Scroll to content"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={handleScrollToContent}
            >
              <ArrowRightIcon className="transform rotate-90 animate-slide1" />
            </motion.button>
          </div>
        </motion.h1>
      </div>
    </section>
  );
};

export default HomepageHeroSection;
