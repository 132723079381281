import { useTransform, useViewportScroll } from 'framer-motion';
import React from 'react';
import LocallySourcedImage from '../../images/locally-sourced.png';
import BgDonut1 from '../atoms/BgDonut1';
import BgDonut2 from '../atoms/BgDonut2';
import BgDonut3 from '../atoms/BgDonut3';
import Button from '../atoms/Button';
import WhiteCard from '../molecules/WhiteCard';

interface WhatMakesUsSectionProps {
  heading: string;
  cards: Array<{
    title: string;
    body: string;
    image: {
      localFile: {
        url: string;
      };
      altText: string;
    };
    buttonLabel: string;
    buttonUrl: string;
  }>;
  buttonLabel: string;
  buttonUrl: string;
}

const WhatMakesUsSection = ({
  heading,
  cards,
  buttonLabel,
  buttonUrl,
}: WhatMakesUsSectionProps) => {
  const { scrollY } = useViewportScroll();
  const y1 = useTransform(scrollY, [0, 4000], [400, -200]);
  const y2 = useTransform(scrollY, [0, 4000], [150, -50]);
  const y3 = useTransform(scrollY, [0, 4600], [300, -50]);

  return (
    <div className="relative pt-8 pb-24 overflow-hidden bg-linen lg:pt-52">
      <BgDonut1
        className="absolute hidden h-auto -left-8 -top-36 opacity-40 w-80 lg:block"
        style={{ y: y1 }}
      />
      <BgDonut2
        className="absolute hidden w-auto right-20 top-56 opacity-40 h-52 lg:block"
        style={{ y: y2 }}
      />
      <BgDonut3
        className="absolute hidden w-64 h-auto bottom-16 left-96 lg:block"
        style={{ y: y3 }}
      />

      <BgDonut2
        className="absolute right-0 w-32 h-auto top-32 opacity-40 lg:hidden"
        style={{ y: y1 }}
      />

      <BgDonut3
        className="absolute left-0 w-32 h-auto lg:hidden bottom-16"
        style={{ y: y3 }}
      />

      <div className="container relative">
        <div className="absolute bottom-0 right-0 z-20">
          <img
            src={LocallySourcedImage}
            alt="Locally Sourced"
            className="relative h-auto select-none w-28 lg:w-36 -top-24 lg:-top-16 lg:left-8"
          />
        </div>

        <h2 className="px-4 mb-12 text-center lg:mb-20 u-h2">{heading}</h2>

        <div className="flex flex-col justify-between space-y-6 lg:flex-row lg:space-y-0 lg:space-x-8">
          {cards.map((card) => (
            <WhiteCard key={card.title} {...card} />
          ))}
        </div>

        <div className="flex justify-center mt-24">
          <Button to={buttonUrl}>{buttonLabel}</Button>
        </div>
      </div>
    </div>
  );
};

export default WhatMakesUsSection;
