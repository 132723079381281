import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/base/Layout';
import Seo from '../components/base/Seo';
import AppHighlightSection from '../components/organisms/AppHighlightSection';
import ExploreTheMenuSection from '../components/organisms/ExploreTheMenuSection';
import FeatureSection from '../components/organisms/FeatureSection';
import FindYourStoreSection from '../components/organisms/FindYourStoreSection';
import HomepageHeroSection from '../components/organisms/HomepageHeroSection';
import NewsletterSection from '../components/organisms/NewsletterSection';
import { PromoPopup } from '../components/organisms/PromoPopup';
import WhatMakesUsSection from '../components/organisms/WhatMakesUsSection';
import WordOnTheStreetSection from '../components/organisms/WordOnTheStreetSection';

const Homepage = ({
  data: {
    page,
    wp: {
      popupSettings: { popupSettings },
    },
  },
}) => {
  return (
    <>
      <Layout transparentHeader>
        <Seo post={page} />

        <HomepageHeroSection
          videoUrl={page.homepage.homepagehero.video?.mediaItemUrl}
          posterUrl={page.homepage.homepagehero.posterImage.localFile.url}
        />
        <FindYourStoreSection
          heading={page.homepage.findYourStore.heading}
          body={page.homepage.findYourStore.body}
          image={page.homepage.findYourStore.image}
          buttonLabel={page.homepage.findYourStore.buttonLabel}
          buttonUrl={page.homepage.findYourStore.buttonUrl}
        />
        <ExploreTheMenuSection
          heading={page.homepage.exploreTheMenu.heading}
          items={page.homepage.exploreTheMenu.images}
          buttonLabel={page.homepage.exploreTheMenu.buttonLabel}
          buttonUrl={page.homepage.exploreTheMenu.buttonUrl}
        />
        <AppHighlightSection {...page.homepage.rewards} />
        <WhatMakesUsSection {...page.homepage.whatMakesUsUs} />
        <FeatureSection {...page.homepage.featuresection} />
        <WordOnTheStreetSection
          testimonials={page.homepage.wordOnTheStreet.testimonials.map(
            (testimonial) => ({
              quote: testimonial.quote,
              name: testimonial.name,
              company: testimonial.company,
              logoUrl: testimonial.logo.localFile.url,
            })
          )}
        />
        <div className="py-12" />
        {/* <InstagramSection /> */}

        <NewsletterSection />
      </Layout>
      <PromoPopup {...popupSettings} />
    </>
  );
};

export const pageQuery = graphql`
  query HomePageQuery($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      ...SEO
      homepage {
        homepagehero {
          video {
            mediaItemUrl
          }
          posterImage {
            localFile {
              url
            }
          }
        }
        findYourStore {
          body
          buttonLabel
          buttonUrl
          heading
          image {
            altText
            localFile {
              url
            }
          }
        }
        exploreTheMenu {
          heading
          images {
            id
            altText
            localFile {
              url
              childImageSharp {
                gatsbyImageData(width: 512, placeholder: BLURRED)
              }
            }
          }
          buttonLabel
          buttonUrl
        }
        rewards {
          heading
          body
          appStoreUrl
          googlePlayUrl
          buttonLabel
          buttonUrl
        }
        whatMakesUsUs {
          heading
          cards {
            title
            body
            image {
              localFile {
                url
              }
              altText
            }
          }
          buttonLabel
          buttonUrl
        }
        wordOnTheStreet {
          testimonials {
            quote
            name
            company
            logo {
              localFile {
                url
              }
              altText
            }
          }
        }
        featuresection {
          heading
          body
          image {
            localFile {
              url
              childImageSharp {
                gatsbyImageData(width: 1472, placeholder: BLURRED)
              }
            }
            altText
          }
          buttonLabel
          buttonUrl
        }
      }
    }
    wp {
      popupSettings {
        popupSettings {
          popupEnabled
          image {
            altText
            localFile {
              url
              childImageSharp {
                gatsbyImageData(width: 1024, placeholder: BLURRED)
              }
            }
          }
          url
        }
      }
    }
  }
`;

export default Homepage;
