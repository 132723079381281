import { motion, useTransform, useViewportScroll } from 'framer-motion';
import React from 'react';
import AppHighlightImage from '../../images/app-highlight-image.png';
import AppHighlightMobile from '../../images/app-highlight-mobile.png';
import WaveLinen from '../../images/wave-linen.png';
import AppStoreIconDark from '../atoms/AppStoreIconDark';
import Button from '../atoms/Button';
import CupIcon from '../atoms/CupIcon';
import DonutNew from '../atoms/DonutNew';
import GooglePlayIconDark from '../atoms/GooglePlayIconDark';
import Sparkle1 from '../atoms/Sparkle1';
import Sparkle2 from '../atoms/Sparkle2';
import Sparkle3 from '../atoms/Sparkle3';

interface AppHighlightSectionProps {
  heading: string;
  body: string;
  appStoreUrl: string;
  googlePlayUrl: string;
  buttonLabel: string;
  buttonUrl: string;
}

const AppHighlightSection = ({
  heading,
  body,
  appStoreUrl,
  googlePlayUrl,
  buttonLabel,
  buttonUrl,
}: AppHighlightSectionProps) => {
  const { scrollY } = useViewportScroll();
  const y1 = useTransform(scrollY, [0, 3000], [100, -150]);
  const y2 = useTransform(scrollY, [0, 3000], [400, -90]);
  const y3 = useTransform(scrollY, [0, 3000], [510, 50]);
  const yDonutOver = useTransform(scrollY, [0, 3000], [100, -100]);
  const yDonutUnder = useTransform(scrollY, [0, 3000], [50, -50]);
  const x = useTransform(scrollY, [0, 5000], [0, -1000]);

  return (
    <section className="relative py-24 lg:py-32">
      <div className="absolute bottom-0 left-0 right-0 h-48 bg-linen -z-1 lg:hidden" />
      <div className="absolute top-0 left-0 right-0 h-8 overflow-hidden">
        <motion.div
          className="w-[10000px] h-full bg-repeat-x"
          style={{
            backgroundImage: `url(${WaveLinen})`,
            backgroundSize: '1.5% 100%',
            x: x,
          }}
        />
      </div>

      <div className="container flex flex-col lg:flex-row">
        <div className="lg:w-1/2">
          <div className="max-w-md pb-16 mx-auto text-center lg:text-left">
            <h2 className="u-h2">{heading}</h2>
            <div
              className="mb-6 u-p"
              dangerouslySetInnerHTML={{ __html: body }}
            />
            <div className="flex items-center justify-center mb-6 space-x-4 lg:justify-start">
              <a
                href={appStoreUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="relative"
                aria-label="Download on the App Store"
              >
                <AppStoreIconDark />

                <div
                  className="absolute transition-all origin-center transform left-1/2 top-1/2 hover:scale-105"
                  style={{ marginTop: '-22px', marginLeft: '-50%' }}
                >
                  <AppStoreIconDark />
                </div>
              </a>
              <a
                href={googlePlayUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="relative"
                aria-label="Download on the Google Play Store"
              >
                <GooglePlayIconDark />

                <div
                  className="absolute transition-all origin-center transform left-1/2 top-1/2 hover:scale-105"
                  style={{ marginTop: '-22px', marginLeft: '-50%' }}
                >
                  <GooglePlayIconDark />
                </div>
              </a>
            </div>

            <Button to={buttonUrl}>{buttonLabel}</Button>
          </div>
        </div>
        <div className="relative lg:w-1/2">
          <Sparkle1
            className="absolute -z-1"
            style={{ left: '54%', bottom: '94%', y: y1 }}
          />
          <Sparkle2
            className="absolute -z-1"
            style={{ left: '8%', top: '44%', y: y2 }}
          />
          <Sparkle3
            className="absolute -z-1"
            style={{ bottom: '31%', right: '13%', y: y3 }}
          />
          <div className="relative top-0 left-0 right-0 z-40 pt-8 overflow-visible lg:absolute xl:px-16">
            <motion.div
              className="absolute hidden right-8 top-32 -z-1 lg:block"
              style={{ y: yDonutUnder }}
            >
              <CupIcon className="w-24 h-auto" />
            </motion.div>
            <img
              src={AppHighlightImage}
              alt="The Salty mobile app screenshot"
              className="hidden w-full h-auto lg:block"
            />
            <img
              src={AppHighlightMobile}
              alt="The Salty mobile app screenshot"
              className="w-full h-auto lg:hidden"
            />
            <motion.div
              className="absolute left-0 hidden top-96 lg:block"
              style={{ y: yDonutOver }}
            >
              <DonutNew className="w-64 h-auto" />
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AppHighlightSection;
