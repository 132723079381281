import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import QuotesIcon from '../atoms/QuotesIcon';
import ArrowButton from '../molecules/ArrowButton';

interface Testimonial {
  quote: string;
  name: string;
  company?: string;
  logoUrl: string;
}

interface WordOnTheStreetSectionProps {
  testimonials: Testimonial[];
}

const WordOnTheStreetSection = ({
  testimonials,
}: WordOnTheStreetSectionProps) => {
  const [index, setIndex] = useState(0);
  const [transitionDirection, setTransitionDirection] = useState<
    'left' | 'right'
  >('right');
  const testimonial = testimonials[index];

  const handlePrev = () => {
    setTransitionDirection('left');
    setTimeout(() => {
      setIndex(index === 0 ? testimonials.length - 1 : index - 1);
    }, 5);
  };

  const handleNext = () => {
    setTransitionDirection('right');
    setTimeout(() => {
      setIndex(index === testimonials.length - 1 ? 0 : index + 1);
    }, 5);
  };

  return (
    <section className="py-16 text-white md:py-16 bg-navy-blue flex flex-col justify-center min-h-[1024px] md:min-h-[800px] lg:min-h-[680px]">
      <div className="container flex items-center justify-between text-center">
        {testimonials.length > 0 && (
          <div className="flex-shrink-0 hidden w-12 md:flex">
            <ArrowButton
              variant="left"
              id="prev-testimonial-btn"
              ariaLabel="Previous testimonial"
              onClick={handlePrev}
            />
          </div>
        )}
        <AnimatePresence exitBeforeEnter>
          <div className="flex-grow overflow-hidden" key={index}>
            <QuotesIcon className="w-auto mx-auto mb-8 h-9" />

            <h2
              className="px-4 mb-6 text-6xl text-transparent uppercase bold font-heading"
              style={{
                WebkitTextStrokeWidth: 1,
                WebkitTextStrokeColor: 'white',
              }}
            >
              Word on the street
            </h2>
            <motion.div
              initial={{
                opacity: 0,
                x: transitionDirection === 'right' ? -150 : 150,
              }}
              animate={{ opacity: 1, x: 0 }}
              exit={{
                opacity: 0,
                x: transitionDirection === 'right' ? 150 : -150,
              }}
              transition={{ type: 'tween' }}
            >
              <q className="block mx-auto mb-6 text-2xl font-bold leading-9 uppercase md:px-6 md:max-w-3xl font-heading lg:px-0">
                {testimonial.quote}
              </q>
              <hr className="w-8 mx-auto mb-4 border-t-0 border-b border-white border-opacity-50" />
              <div className="mb-6 text-lg text-white u-p">
                {testimonial.name}
                {testimonial.company && (
                  <>
                    , <strong>{testimonial.company}</strong>
                  </>
                )}
              </div>
              <div className="relative flex items-center justify-center w-40 h-20 mx-auto">
                <img
                  src={testimonial.logoUrl}
                  alt={`${testimonial.company} logo`}
                  className="object-contain w-auto h-full"
                />
              </div>
            </motion.div>
          </div>
        </AnimatePresence>
        {testimonials.length > 0 && (
          <div className="flex-shrink-0 hidden w-12 md:flex">
            <ArrowButton
              variant="right"
              id="next-testimonial-btn"
              ariaLabel="Next testimonial"
              onClick={handleNext}
            />
          </div>
        )}
      </div>

      {testimonials.length > 0 && (
        <div className="flex items-center justify-center mt-4 space-x-6 md:hidden">
          <div className="flex-shrink-0 w-12">
            <ArrowButton
              variant="left"
              onClick={handlePrev}
              ariaLabel="Previous testimonial"
            />
          </div>
          <div className="flex-shrink-0 w-12">
            <ArrowButton
              variant="right"
              onClick={handleNext}
              ariaLabel="Next testimonial"
            />
          </div>
        </div>
      )}
    </section>
  );
};

export default WordOnTheStreetSection;
