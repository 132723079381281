import React from 'react';

const QuotesIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="52"
    height="38"
    fill="none"
    viewBox="0 0 52 38"
    {...props}
  >
    <path
      fill="#DFC2C2"
      d="M51.072 36.646L31.562 38c-.171-1.41-.342-2.934-.513-4.57a249.712 249.712 0 01-.343-4.655 171.344 171.344 0 01-.085-5.416c0-6.094 1.454-11.003 4.364-14.726 2.91-3.78 7.673-6.658 14.29-8.633L51.5 6.855c-1.997.282-3.737.847-5.22 1.693-1.426.846-2.624 1.834-3.594 2.962a13.49 13.49 0 00-2.139 3.724 12.652 12.652 0 00-.685 4.062h11.21v17.35zm-30.035 0L1.527 38c-.171-1.41-.342-2.934-.514-4.57a249.753 249.753 0 01-.342-4.655A85.864 85.864 0 01.5 23.36c0-6.094 1.426-11.003 4.279-14.726C7.688 4.853 12.45 1.975 19.069 0l2.396 6.855c-1.997.282-3.765.847-5.306 1.693-1.483.846-2.71 1.834-3.68 2.962a12.455 12.455 0 00-2.224 3.724 12.653 12.653 0 00-.685 4.062h11.467v17.35z"
    ></path>
  </svg>
);

export default QuotesIcon;
