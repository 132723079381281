import { motion, useTransform, useViewportScroll } from 'framer-motion';
import React from 'react';
import TheSaltySketch from '../../images/the-salty-sketch.png';
import Button from '../atoms/Button';
import TheSaltyCursive from '../atoms/TheSaltyCursive';

interface FindYourStoreSectionProps {
  heading: string;
  body: string;
  image: {
    localFile: { url: string };
    altText: string;
  };
  buttonLabel: string;
  buttonUrl: string;
}

const FindYourStoreSection = ({
  heading,
  body,
  image: {
    altText,
    localFile: { url: imageUrl },
  },
  buttonLabel,
  buttonUrl,
}: FindYourStoreSectionProps) => {
  const { scrollY } = useViewportScroll();
  const x1 = useTransform(scrollY, [0, 2600], [0, 100]);
  const y1 = useTransform(scrollY, [0, 2600], [-120, 120]);
  const y2 = useTransform(scrollY, [0, 2600], [50, -100]);

  return (
    <section className="relative pt-24 lg:pt-32" id="find-your-store">
      <div className="absolute left-0 right-0 h-full overflow-hidden -bottom-60 md:bottom-0 -z-1">
        <TheSaltyCursive
          className="absolute right-0 w-auto h-56 md:bottom-12 md:h-80"
          style={{ x: x1 }}
        />
      </div>
      <div className="absolute bottom-0 left-0 right-0 w-full h-16 bg-linen -z-1" />

      <div className="container flex flex-col-reverse items-center lg:flex-row">
        <div className="relative lg:w-1/2">
          <div className="relative overflow-hidden">
            <img
              src={imageUrl}
              alt={altText}
              className="w-full h-auto opacity-0"
            />
            <motion.div
              className="absolute inset-0 bg-center bg-contain"
              style={{
                width: '120%',
                height: '120%',
                backgroundImage: `url(${imageUrl})`,
                y: y1,
              }}
            />
          </div>
          <motion.div
            className="absolute bottom-0 -right-4 lg:right-0"
            style={{ y: y2 }}
          >
            <img
              src={TheSaltySketch}
              alt="The Salty"
              className="relative w-auto h-28 top-14 lg:left-20"
            />
          </motion.div>
        </div>
        <div className="flex items-center lg:w-1/2">
          <div className="max-w-xl mx-auto mb-12 text-center lg:max-w-sm lg:mb-24 lg:text-left">
            <h1 className="u-h2">{heading}</h1>
            <div
              className="mb-6 u-p"
              dangerouslySetInnerHTML={{ __html: body }}
            />

            <Button to={buttonUrl}>{buttonLabel}</Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FindYourStoreSection;
